const state = {
  users: [],
  meta: {
    current_page: 0,
    last_page: 0,
    per_page: 0,
    total: 0,
  },
  userTransactions: [],
};

const mutations = {
  SET_USERS(state, users) {
    state.users = users;
  },
  SET_META(state, meta) {
    state.meta = meta;
  },
  SET_TRANSACTIONS(state, transactions) {
    state.userTransactions = transactions;
  },
};

const getters = {
  getAllUsers: (state) => state.users,
  getMeta: (state) => state.meta,
  getUserTransactions: (state) => state.userTransactions,
};

export default {
  state,
  mutations,
  getters,
};
