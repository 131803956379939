<template>
  <div class="container table-responsive">
    <modal v-if="openDeleteModal" @closeModal="handleCancel()">
      <template #modalContent>
        <h2 class="modal-question">
          {{ `Czy chcesz usunąć obiekt ${objectToDelete}` }}
        </h2>
        <div class="d-flex">
          <b-button
            style="margin-left: 10px"
            class="btn btn-sm ml-1"
            variant="outline-danger"
            @click="handleDelete()"
            >Usuń
          </b-button>
          <b-button
            style="margin-left: 10px"
            class="btn btn-sm ml-1"
            variant="outline-primary"
            @click="handleCancel($event)"
            >Anuluj
          </b-button>
        </div>
      </template>
    </modal>
    <modal v-if="showAddOrEditModal" @closeModal="handleCancel()">
      <template #modalContent>
        <h2>
          {{
            objectTypeAction === "ADD" ? "Dodaj nowy obiekt" : "Edytuj obiekt"
          }}
        </h2>
        <div class="add-object-wrapper">
          <div>
            <label for="name">Nazwa obiektu</label>
            <input id="name" type="text" v-model="objectToAddOrEdit.name" />
          </div>
          <div>
            <label for="description">Opis</label>
            <input
              id="description"
              type="text"
              v-model="objectToAddOrEdit.description"
            />
          </div>
          <div>
            <label for="map">Adres</label>
            <vue-google-autocomplete
              id="map"
              v-model="objectToAddOrEdit.address"
              placeholder="Zacznij pisać"
              v-on:placechanged="getAddressData"
            />
          </div>
          <div>
            <label for="website">Strona internetowa (niewymagane)</label>
            <input
              id="website"
              type="text"
              v-model="objectToAddOrEdit.website"
            />
          </div>
          <div>
            <label for="picutre">Zdjęcie</label>
            <input
              id="picture"
              type="file"
              @change="handleChangeImage"
              accept="image/png, image/jpeg"
            />
          </div>
          <ul v-if="imagesToDsiplay.length" class="images-to-display">
            <li v-for="{ id, src } in imagesToDsiplay" :key="id">
              <img :src="src" />
              <div
                class="modal-cancel-btn"
                style="top: -5px; right: -5px"
                @click="handleDeleteImage(id)"
              >
                x
              </div>
            </li>
          </ul>
          <div>
            <label for="userId">Id właściciela klubu (niewymagane)</label>
            <input
              id="userId"
              type="number"
              v-model="objectToAddOrEdit.ownerId"
            />
          </div>
          <div>
            <p>Godziny otwarcia (niewymagane)</p>
            <div v-for="index in 7" :key="index">
              <label>{{ WEEK_DAYS[index - 1] }}</label>
              <span style="display: block">Otwarcie</span>
              <input
                type="time"
                :style="
                  objectToAddOrEdit.openingHours[index - 1].isClosedAllDay
                    ? 'background-color: gray; pointer-events: none'
                    : ''
                "
                v-model="objectToAddOrEdit.openingHours[index - 1].open.time"
                onfocus="this.showPicker()"
              />
              <span style="display: block">Zamknięcie</span>
              <input
                type="time"
                :style="
                  objectToAddOrEdit.openingHours[index - 1].isClosedAllDay
                    ? 'background-color: gray; pointer-events: none'
                    : ''
                "
                v-model="objectToAddOrEdit.openingHours[index - 1].close.time"
                onfocus="this.showPicker()"
              />
              <b-button
                style="margin-top: 10px"
                class="btn btn-sm"
                variant="outline-primary"
                @click="
                  objectToAddOrEdit.openingHours[index - 1].open.time = '';
                  objectToAddOrEdit.openingHours[index - 1].close.time = '';
                  objectToAddOrEdit.openingHours[index - 1].isClosedAllDay =
                    !objectToAddOrEdit.openingHours[index - 1].isClosedAllDay;
                "
                >{{
                  objectToAddOrEdit.openingHours[index - 1].isClosedAllDay
                    ? "Ustaw punkt na otwarty"
                    : "Utaw punkt na zamknięty"
                }}
              </b-button>
            </div>
          </div>
        </div>
        <div class="d-flex">
          <b-button
            style="margin-left: 10px"
            class="btn btn-sm ml-1"
            variant="outline-primary"
            @click="handleAddOrEdit()"
            >{{ objectTypeAction === "ADD" ? "Dodaj obiekt" : "Zapisz zmiany" }}
          </b-button>
          <b-button
            style="margin-left: 10px"
            class="btn btn-sm ml-1"
            variant="outline-danger"
            @click="handleCancel($event)"
            >Anuluj
          </b-button>
        </div>
      </template>
    </modal>
    <p class="h1 fw-light mb-2 mb-sm-4">Obiekty</p>
    <div class="filter-wrapper">
      <h2>Filtry</h2>
      <div class="filter-actions-wrapper">
        <div class="filter-object-wrapper">
          <span>Filtr nazwy obiektu</span>
          <input type="text" v-model="query" />
        </div>
        <!-- <div class="filter-object-wrapper">
          <span>Filtr specjalizacji</span>
          <input type="text" v-model="specjalizationFilter" />
        </div> -->
        <div class="filter-object-wrapper">
          <span>Filtr opisu obiektu</span>
          <input type="text" v-model="descriptionFilter" />
        </div>
        <div class="filter-object-wrapper">
          <span>Filtr użytkowników</span>
          <input type="text" v-model="userFilter" />
        </div>
        <div>
          <b-button
            style="width: 80px; margin-left: 10px; font-weight: 700"
            class="btn btn-sm ml-1"
            variant="outline-primary"
            id="filter-button"
            @click="handleFetch"
            >Filtruj
          </b-button>
          <b-button
            style="margin-left: 10px; font-weight: 700"
            class="btn btn-sm ml-1"
            variant="outline-primary"
            @click="handleReset"
            >Wyczyść filtr
          </b-button>
        </div>
      </div>
    </div>
    <b-button
      style="margin-bottom: 10px; font-weight: 700"
      class="btn btn-sm ml-1"
      variant="outline-primary"
      @click="handleShowAddOrEditModal('ADD', {})"
    >
      Dodaj nowy obiekt
    </b-button>
    <table
      class="table align-middle"
      v-if="objects && objects.length > 0"
      style="margin-bottom: 30px"
    >
      <thead>
        <tr>
          <th v-for="column in columns" :key="column">
            {{ column }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="object in objects" :key="object.id">
          <td style="position: relative">
            <div class="action-column">
              <div
                @click="handleShowModal(object.id, object.name)"
                class="danger-button"
              >
                <span>x</span>
                <span class="hint">Usuń obiekt</span>
              </div>
            </div>
          </td>
          <td>
            {{ object.name || "-" }}
          </td>
          <td>
            {{ object.address || "-" }}
          </td>
          <td>
            {{ object.owner ? object.owner.name : "-" }}
          </td>
          <td>
            {{ object.description || "-" }}
          </td>
          <td>
            {{ object.website || "-" }}
          </td>
          <td>
            <div v-if="object.openingHours">
              <p
                v-for="(day, index) in object.openingHours"
                :key="index"
                style="margin: 0"
              >
                {{
                  `${WEEK_DAYS[index]}: ${
                    day.isClosedAllDay
                      ? "Zamknięte"
                      : `${handleParseHours(day.open.time)}-${handleParseHours(
                          day.close.time
                        )}`
                  }`
                }}
              </p>
            </div>
            <span v-else>-</span>
          </td>
          <td>
            <p class="link" @click="handleShowAddOrEditModal('EDIT', object)">
              Edytuj obiekt
            </p>
            <p class="link" @click="handleShowGallery(object.images)">
              Pokaż Zdjęcia
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <empty-list-alert v-else :content="'Brak obiektów'"></empty-list-alert>
    <gallery
      v-if="showImagesModal"
      :images="imagesToDsiplay"
      @close="handleCloseGallery"
    />
    <pagination
      v-if="isDownPaginationVisible"
      class="mx-auto"
      :dispatchActionName="`fetchObjects`"
      :perPage="meta.per_page"
      :pageNumber="meta.current_page"
      :total="meta.total"
      :status="statusValue"
      :search-user="query"
    ></pagination>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import shared from "../shared";
import Pagination from "../components/Pagination";
import Gallery from "../components/Gallery.vue";
import EmptyListAlert from "../components/EmptyListAlert.vue";
import Modal from "../components/Modal.vue";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import {
  getNewObjectData,
  praseObjectToEdit,
  handleFilterKeyPress,
  parseHours,
} from "../utils/helpers";

export default {
  name: "Objects",

  components: {
    Pagination,
    EmptyListAlert,
    Modal,
    Gallery,
    VueGoogleAutocomplete,
  },

  data: () => {
    return {
      columns: [
        "",
        "Nazwa",
        "Adres",
        "Autor",
        "Opis",
        "Strona internetowa",
        "Godziny otwarcia",
        "Akcje",
      ],
      statusValue: "",
      openDeleteModal: false,
      objectToDelete: "",
      objectToDeleteId: "",
      resetMeta: false,
      query: "",
      specjalizationFilter: "",
      descriptionFilter: "",
      userFilter: "",
      statusToSet: "",
      showBlockModal: false,
      detailsObject: {},
      showImagesModal: false,
      WEEK_DAYS: [
        "Poniedziałek",
        "Wtorek",
        "Środa",
        "Czwartek",
        "Piątek",
        "Sobota",
        "Niedziela",
      ],
      objectToAddOrEdit: getNewObjectData(),
      showAddOrEditModal: false,
      objectTypeAction: "ADD",
      objectImages: [],
      FILE_TYPES: ["jpg", "jpeg", "png"],
    };
  },

  methods: {
    ...mapActions([
      "fetchObjects",
      "addObject",
      "editObject",
      "deleteObject",
      "addImage",
      "fetchPicture",
      "deleteImage",
    ]),
    async handleFetch() {
      try {
        await this.fetchObjects({
          ...this.meta,
          ...(this.resetMeta ? { current_page: 1 } : {}),
          query: this.query,
          description: this.descriptionFilter,
          user: this.userFilter,
          status: this.statusValue,
        });
      } catch (err) {
        if (err.status === 403) {
          this.$toast.open({
            message: `Operacja zabroniona`,
            type: "error",
          });
        } else if (err.status === 404) {
          this.$toast.open({
            message: `Obiekt nieznaleziony`,
            type: "error",
          });
        } else if (err.status === 500) {
          this.$toast.open({
            message: `Wystąpił błąd serwera`,
            type: "error",
          });
        }
      }
      this.formattedDateAsNumbers = shared.formattedDateAsNumbers;
    },
    async handleReset() {
      this.query = "";
      this.statusValue = "";
      this.descriptionFilter = "";
      this.userFilter = "";
      this.resetMeta = true;
      await this.handleFetch();
      this.resetMeta = false;
    },
    async handleCancel() {
      this.objectToDelete = "";
      this.objectToDeleteId = "";
      this.objectToAddOrEdit = getNewObjectData();
      this.showBlockModal = false;
      this.openDeleteModal = false;
      this.showAddOrEditModal = false;
      this.objectImages = [];
      await this.handleFetch();
    },
    async handleShowGallery(images) {
      if (images && images.length) {
        this.objectImages = await Promise.all(
          images
            //.filter((img) => !img.compressFileId)
            .map((img) => this.fetchPicture(img.id))
        );
        this.showImagesModal = true;
      } else {
        this.$toast.open({
          message: `Galeria jest pusta`,
          type: "error",
        });
      }
    },
    handleCloseGallery() {
      this.objectImages = [];
      this.showImagesModal = false;
    },
    handleShowModal(id, name) {
      this.objectToDelete = name;
      this.objectToDeleteId = id;
      this.openDeleteModal = true;
    },
    async handleDeleteImage(id) {
      const imageToDeleteIndex = this.objectImages.findIndex(
        (img) => img.id === id
      );
      if (imageToDeleteIndex !== -1) {
        this.objectImages.splice(imageToDeleteIndex, 1);
        if (typeof id === "number") {
          await this.deleteImage(id);
        }
      } else {
        this.$toast.open({
          message: `Nie znaleziono zdjęcia`,
          type: "error",
        });
      }
    },
    getAddressData(addressData) {
      const { latitude, longitude, country, locality, route, street_number } =
        addressData;
      this.objectToAddOrEdit.lng = longitude;
      this.objectToAddOrEdit.lat = latitude;
      this.objectToAddOrEdit.address = `${route || ""} ${street_number || ""} ${
        locality || ""
      } ${country || ""}`;
    },
    handleChangeImage(e) {
      const file = e.target.files[0];
      const extension = file.name.split(".").pop();
      if (this.FILE_TYPES.includes(extension)) {
        if (this.objectImages.length < 5) {
          this.objectImages.push({
            id: `${new Date().getTime()}`,
            file,
          });
        } else {
          this.$toast.open({
            message: `Maksymalna licza zdjęć to 5`,
            type: "error",
          });
        }
      } else {
        this.$toast.open({
          message: `Możliwe rodzaje plików to: jpg, jpeg i png`,
          type: "error",
        });
      }
    },
    handleParseHours(hour) {
      return parseHours(hour);
    },
    async handleShowAddOrEditModal(type, data) {
      this.objectTypeAction = type;
      if (type === "EDIT") {
        const tempId = data.id;
        this.objectToAddOrEdit = praseObjectToEdit(data);
        this.objectToAddOrEdit.objectId = tempId;
        if (data.images && data.images.length)
          this.objectImages = await Promise.all(
            data.images
              .filter((img) => !img.compressFileId)
              .map((img) => this.fetchPicture(img.id))
          );
      }
      this.showAddOrEditModal = true;
    },
    async handleDelete() {
      try {
        await this.deleteObject({
          objectId: this.objectToDeleteId,
        });
        this.objectToDelete = "";
        this.objectToDeleteId = "";
        await this.handleFetch();
        this.$toast.open({
          message: `Usunięto obiekt`,
          type: "success",
        });
      } catch (err) {
        if (err.status === 404) {
          this.$toast.open({
            message: `Nie znaleziono obiektu`,
            type: "error",
          });
        } else if (err.status === 500) {
          this.$toast.open({
            message: `Wystąpił błąd serwera`,
            type: "error",
          });
        }
      }
      this.formattedDateAsNumbers = shared.formattedDateAsNumbers;
      this.openDeleteModal = false;
    },
    async handleAddOrEdit() {
      try {
        if (
          this.objectToAddOrEdit.description &&
          this.objectToAddOrEdit.lat &&
          this.objectToAddOrEdit.lng &&
          this.objectToAddOrEdit.name &&
          this.objectToAddOrEdit.address
        ) {
          if (this.objectToAddOrEdit.ownerId !== null) {
            this.objectToAddOrEdit.ownerId = parseInt(
              this.objectToAddOrEdit.ownerId
            );
          }
          if (!this.objectToAddOrEdit.address) {
            delete this.objectToAddOrEdit.address;
          }
          this.objectToAddOrEdit.lat = parseFloat(this.objectToAddOrEdit.lat);
          this.objectToAddOrEdit.lng = parseFloat(this.objectToAddOrEdit.lng);
          let response = null;
          if (this.objectTypeAction === "ADD") {
            response = await this.addObject(this.objectToAddOrEdit);
          } else if (this.objectTypeAction === "EDIT") {
            response = await this.editObject(this.objectToAddOrEdit);
          } else {
            this.$toast.open({
              message: `Wsytąpił błąd z typem akcji na obiekcie`,
              type: "error",
            });
          }
          if (this.objectImages.length && response) {
            await Promise.all(
              this.objectImages
                .map((img) =>
                  typeof img.id === "string"
                    ? this.addImage({
                        file: img.file,
                        objectId: response.id,
                        type: "OBJECT",
                      })
                    : undefined
                )
                .filter((e) => e !== undefined)
            );
          }
          this.objectImages = [];
          this.objectToAddOrEdit = getNewObjectData();
          await this.handleFetch();
          if (this.objectTypeAction === "EDIT") {
            this.$toast.open({
              message: `Obiekt zaktualizowany pomyślnie`,
              type: "success",
            });
          } else if (this.objectTypeAction === "ADD") {
            this.$toast.open({
              message: `Obiekt dodany pomyślnie`,
              type: "success",
            });
          }
          this.showAddOrEditModal = false;
        } else {
          this.$toast.open({
            message: `Pola wymagane nie mogą być puste`,
            type: "error",
          });
        }
      } catch (err) {
        console.log(err.response);
        if (
          err.response.status === 403 &&
          err.response.data.message === "Object already exists"
        ) {
          this.$toast.open({
            message: `Obiekt o takiej nazwie już istnieje`,
            type: "error",
          });
        } else if (
          err.response.status === 404 &&
          err.response.data.message === "USER NOT FOUND"
        ) {
          this.$toast.open({
            message: `Nie znaleziono użytkownika`,
            type: "error",
          });
        } else if (err.response.status === 404) {
          this.$toast.open({
            message: `Nie znaleziono obiektu`,
            type: "error",
          });
        } else if (err.response.status === 500) {
          this.$toast.open({
            message: `Wystąpił błąd serwera`,
            type: "error",
          });
        }
      }
    },
    handleFetchWithStatus(status) {
      this.statusValue = status;
      this.resetMeta = true;
      this.handleFetch();
      this.resetMeta = false;
    },
  },

  async created() {
    try {
      await this.fetchObjects({
        per_page: 10,
        current_page: 1,
        query: "",
        status: "",
      });
      this.formattedDateAsNumbers = shared.formattedDateAsNumbers;
    } catch (err) {
      if (err.status === 500) {
        this.$toast.open({
          message: `Wystąpił błąd serwera`,
          type: "error",
        });
      }
    }
  },

  mounted() {
    window.addEventListener("keypress", handleFilterKeyPress);
  },

  beforeDestroy() {
    window.removeEventListener("keypress", handleFilterKeyPress);
  },

  computed: {
    ...mapGetters({
      objects: "getObjects",
      meta: "getObjectsMeta",
    }),
    imagesToDsiplay() {
      return this.objectImages.map((img) => ({
        id: img.id,
        src: URL.createObjectURL(img.file),
      }));
    },

    isDownPaginationVisible() {
      return this.meta && this.meta.total > 20;
    },
  },
};
</script>

<style scoped lang="scss">
tr {
  border-bottom-width: 1px;
}

td {
  border-bottom-width: 0px;
}
.action-column {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-bottom-width: 0px;
}

.action-column div {
  cursor: pointer;
  position: relative;
}

.action-column div img {
  width: 22px;
  height: 22px;
}

.action-column div:hover .hint {
  display: block;
}
</style>
