<template>
  <div class="blur-modal-wrapper">
    <section class="modal-wrapper">
      <div class="modal-cancel-btn" @click="emitCloseModal">
        <span>x</span>
      </div>
      <slot name="modalContent"></slot>
    </section>
  </div>
</template>

<script>
export default {
  name: "Modal",
  emits: ["closeModal"],
  props: ["enableScroll"],
  methods: {
    emitCloseModal() {
      this.$emit("closeModal");
    },
  },
};
</script>
