import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
import createPersistedState from "vuex-persistedstate";
import actions from "./actions";
import auth from "./modules/auth";
import users from "./modules/users";
import objects from "./modules/objects";
import events from "./modules/events";
import posts from "./modules/posts";

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      key: "FUCHA_STATE",
      paths: ["auth", "users", "objects", "events", "posts"],
    }),
  ],
  actions,
  modules: {
    auth,
    users,
    objects,
    events,
    posts,
  },
});
