import axios from "../../axios.js";

const fetchPosts = async ({ commit }, data) => {
  const perPage = data.per_page ? `limit=${data.per_page}&` : "";
  const pageNumber = data.current_page ? `page=${data.current_page}&` : "";
  const query = data.query ? `query=${data.query}&` : "";
  const url = `posts?${perPage}${pageNumber}${query}`;
  try {
    const response = await axios.get(url);
    commit("SET_POSTS", response.data.data);
    commit("SET_META_POSTS", response.data.meta);
  } catch (e) {
    throw { status: e.response.status };
  }
};

const showComments = async (_, data) => {
  const perPage = data.per_page ? `limit=${data.per_page}&` : "";
  const pageNumber = data.current_page ? `page=${data.current_page}&` : "";
  const url = `admins/posts/${data.postId}/comments?${perPage}${pageNumber}`;
  try {
    const response = await axios.get(url);
    return response.data;
  } catch (e) {
    throw { status: e.response.status };
  }
};

const deleteComment = async (_, data) => {
  try {
    const response = await axios.delete(`admins/comments/${data.id}`);
    return response.data.status;
  } catch (e) {
    throw { status: e.response.status };
  }
};

const deletePost = async (_, data) => {
  try {
    const response = await axios.delete(`admins/posts/${data.id}`);
    return response.data.status;
  } catch (e) {
    throw { status: e.response.status };
  }
};

export default {
  fetchPosts,
  deletePost,
  showComments,
  deleteComment,
};
