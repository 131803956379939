<template>
  <div class="login mx-auto py-5">
    <div class="logo rounded w-100">
      <img src="../assets/pickme-logo.svg" alt="" />
    </div>

    <b-form @submit.prevent="handleLogin()" class="login-form">
      <div>
        <b-input-group prepend="Login" class="login-field">
          <b-form-input
            id="login"
            placeholder="Wprowadź login"
            v-model="phone"
          ></b-form-input>
        </b-input-group>
      </div>
      <div>
        <b-input-group prepend="Hasło" class="login-field">
          <b-form-input
            type="password"
            id="password"
            placeholder="Wprowadź hasło"
            v-model="code"
          ></b-form-input>
        </b-input-group>
      </div>
      <b-button
        type="submit"
        class="btn btn-sm w-100"
        variant="outline-primary"
        >{{ "Zaloguj się" }}</b-button
      >
    </b-form>
    <small class="errorMessage mt-1 text-danger" v-show="errorMessage">{{
      errorMessage
    }}</small>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Login",
  data: () => {
    return {
      phone: "",
      code: "",
      errorMessage: "",
    };
  },
  created() {
    if (this.token !== null) {
      this.$router.push({ path: "/users" });
    }
  },
  methods: {
    ...mapActions(["login"]),

    async handleLogin() {
      const data = {
        phoneNumber: this.phone,
        verificationCode: this.code,
      };
      try {
        await this.login(data);
      } catch (err) {
        if (err.status === 500) {
          this.$toast.open({
            message: `Wystąpił błąd serwera, spróbuj ponownie później.`,
            type: "error",
          });
        } else if (err.status === 404) {
          this.$toast.open({
            message: `Nie znaleziono konta`,
            type: "error",
          });
        } else {
          this.errorMessage = "Niepoprawne dane logowania";
        }
      }
    },
  },

  computed: {
    ...mapGetters({
      token: "getToken",
    }),
  },
};
</script>

<style scoped>
.login-form {
  padding: 0 !important;
  margin: 0 !important;
  min-width: 98%;
}
.login {
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90vh;
}
.login-field {
  text-align: left;
  margin-bottom: 1rem;
  font-weight: 100;
}
.logo img {
  width: 450px;
  height: 200px;
  object-fit: contain;
}
button {
  font-weight: 700;
}
/* .errorMessage {
  color: white;
} */
.form-control,
.input-group-text {
  border-radius: 0;
  width: 140px;
}

@media (max-width: 520px) {
  .logo img {
    width: 300px;
  }
  .form-control {
    font-size: 10px !important;
  }
  .input-group-prepend {
    width: 100px !important;
    margin-right: 0;
  }
  .input-group {
    min-width: 0;
  }
  .login {
    width: 95%;
  }
  .input-group-text {
    font-size: 11px;
  }
}
</style>
