export const USER_STATUSES = {
  WAITING_FOR_ACCEPTANCE: "W trakcie logowania",
  BLOCKED: "Zablokowany",
  ACTIVATED: "Aktywny",
  DELETED: "Usunięty",
  UNLOCKED: "Odblokowany",
};

export const JOB_STATUSES = {
  ACTIVE: "Aktywne",
  PENDING: "W trakcie",
  EXPIRED: "Wygasłe",
  FINISHED: "Zakończone",
  DELETED: "Usunięte",
  UNREALIZED_WAITING: "Trwa spór",
  UNREALIZED_ORDERER: "Niezrealizowane (zleceniodawca)",
  UNREALIZED_CONTRACTOR: "Niezrealizowane (zleceniobiorca)",
};

export const GENERAL_STATUSES = {
  ACCEPTED: "Zaakceptowane",
  WAITING: "Oczekujące",
  REJECTED: "Odrzucone",
};

export const TRANSACTION_STATUSES = {
  WALLET_TOP_UP: "Doładowanie portfela",
  PAY_FOR_OFFER: "Zapłata za ofertę",
  OFFER_REMUNERATION: "Wynagrodzenie za ofertę",
  PAYOUT_FROM_WALLET: "Zapłata z portfela",
  REJECTED_PAYOUT_FROM_WALLET: "Odrzucona wypłata z portfela",
  REFUND_FOR_UNREALIZED_ADVERTISEMENT: "Zwrot za niezrealizowane ogłoszenie",
};
