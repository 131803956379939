import axios from "../../axios";

const addImage = async (_, data) => {
  const formData = new FormData();
  formData.append("file", data.file);
  if (data.objectId) {
    formData.append("objectId", data.objectId);
  } else if (data.eventId) {
    formData.append("eventId", data.eventId);
  }
  if (data.type) {
    formData.append("type", data.type);
  }
  const url = `cdn/upload`;
  try {
    await axios.post(url, formData);
  } catch (e) {
    throw { status: e.reponse.status };
  }
};

const fetchPicture = async (_, picId) => {
  const url = `cdn/files/${picId}`;
  try {
    const response = await axios.get(url, {
      responseType: "blob",
    });
    return {
      id: picId,
      file: response.data,
    };
  } catch (e) {
    throw { status: e.response.status };
  }
};

const deleteImage = async (_, picId) => {
  const url = `cdn/delete/${picId}`;
  try {
    await axios.delete(url);
  } catch (e) {
    throw { status: e.reponse.status };
  }
};

export default {
  addImage,
  fetchPicture,
  deleteImage,
};
