const state = {
  token: null
};

const mutations = {
  SET_TOKEN(state, token) {
    state.token = token;
  },
};

const getters = {
  getToken: (state) => state.token
};

export default {
  state,
  mutations,
  getters,
};
