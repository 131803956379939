import moment from "moment";

export default {
  formattedDate: function (date) {
    moment.locale("pl");
    return moment(date).format("MMMM Do YYYY, h:mm:ss a");
  },

  formattedDateAsNumbers: function (date) {
    moment.locale("pl");
    return moment(date).format("YYYY-MM-DD, HH:mm");
  },
  formattedDateWithoutTime: function (date) {
    moment.locale("pl");
    return moment(date).format("DD MMMM YYYY");
  },
  dateWithoutTimeToString: function (date) {
    moment.locale("pl");
    return moment(date).format("YYYY-MM-DD");
  },
  dateWithoutTimeToStringSeparatedWithSlash: function (date) {
    moment.locale("pl");
    return moment(date).format("YYYY/MM/DD");
  },

  startOfDayFromTimestampDate: function (date) {
    moment.locale("pl");
    return moment(date).startOf("day").toString();
  },
  endOfDayFromTimestampDate: function (date) {
    moment.locale("pl");
    return moment(date).endOf("day").toString();
  },
  isToday: function (date) {
    return moment(date).isSame(moment(), "day");
  },
  isFuture: function (date) {
    return moment(date).isAfter(moment(), "day");
  },
  formattedDateToString: function (date) {
    moment.locale("pl");
    return moment(date).format("LLL");
  },
  isValidDate: function (date) {
    return moment(date, "YYYY/MM/DD", true).isValid();
  },
};
