export const parseDate = (date) => {
  const newDate = new Date(date);
  const day = newDate.getDate();
  const month =
    newDate.getMonth() + 1 > 10
      ? newDate.getMonth() + 1
      : `0${newDate.getMonth() + 1}`;
  const year = newDate.getFullYear();
  return `${day}/${month}/${year}`;
};

export const parseHours = (hour) => {
  if (hour) {
    const hourWithoutColon = hour.split(":").join("");
    return `${hourWithoutColon.slice(0, 2)}:${hourWithoutColon.slice(2, 4)}`;
  } else {
    return "";
  }
};

const getOpeningHours = () => {
  const hoursArray = [];
  for (let i = 0; i < 7; i++) {
    hoursArray.push({
      open: {
        day: i + 1,
        time: "",
      },
      close: {
        day: i + 1,
        time: "",
      },
      isClosedAllDay: false,
    });
  }
  return hoursArray;
};

export const getNewObjectData = () => {
  return {
    description: "",
    lat: 0.0,
    lng: 0.0,
    name: "",
    address: "",
    website: "",
    ownerId: null,
    openingHours: getOpeningHours(),
  };
};

export const praseObjectToEdit = (object) => {
  const {
    description,
    lat,
    lng,
    name,
    address,
    website,
    ownerId,
    openingHours,
  } = object;
  return {
    description: description || "",
    lat: lat || object.latitude || 0,
    lng: lng || object.longitude || 0,
    name: name || "",
    address: address || "",
    website: website || "",
    ownerId: ownerId || null,
    openingHours: openingHours || getOpeningHours(),
  };
};

export const handleFilterKeyPress = (event) => {
  const btn = document.querySelector("#filter-button");
  if (event.key === "Enter" && btn) {
    btn.click();
  } else if (!btn) {
    this.$toast.open({
      message: `Wystąpił błąd`,
      type: "error",
    });
  }
};
