import axios from "../../axios.js";

const fetchUsers = async ({ commit }, data) => {
  const perPage = data.per_page ? `limit=${data.per_page}&` : "";
  const pageNumber = data.current_page ? `page=${data.current_page}&` : "";
  const searchUser = data.searchUser ? `query=${data.searchUser}&` : "";
  const phoneNumber = data.phoneNumber ? `phoneNumber=${data.phoneNumber}` : "";
  const status = data.status ? `types=${data.status}` : "";
  const url = `admins/users?${perPage}${pageNumber}${searchUser}${phoneNumber}${status}`;
  try {
    const response = await axios.get(url);
    commit("SET_USERS", response.data.data);
    commit("SET_META", response.data.meta);
  } catch (e) {
    throw { status: e.response.status };
  }
};

const fetchUserDetails = async (_, data) => {
  try {
    const response = await axios.get(`admins/users/${data.userId}`);
    return response.data;
  } catch (e) {
    throw { status: e.response.status };
  }
};

const deleteUser = async (_, data) => {
  try {
    const response = await axios.delete(`admins/users/${data.id}`);
    return response.data.status;
  } catch (e) {
    throw { status: e.response.status };
  }
};

const blockOrUnblockUser = async (_, data) => {
  try {
    const response = await axios.put(`admins/statuses`, {
      userId: data.userId,
      status: data.actionType,
    });
    return response.data.status;
  } catch (err) {
    throw { status: err.response.status };
  }
};

const fetchUserPicture = async (_, picId) => {
  const url = `cdn/files/${picId}`;
  try {
    const response = await axios.get(url, {
      responseType: "blob",
    });
    console.log(response);
    return URL.createObjectURL(response.data);
  } catch (e) {
    throw { status: e.response.status };
  }
};

export default {
  fetchUsers,
  deleteUser,
  blockOrUnblockUser,
  fetchUserPicture,
  fetchUserDetails,
};
