<template>
  <div class="blur-modal-wrapper">
    <section class="modal-wrapper" style="overflow-y: unset; overflow-x: unset">
      <img :src="image" alt="Avatar image" class="avatar-img" />
      <div class="modal-hide-avatar-img">
        <span @click="emitCloseModal">Zamknij zdjęcie</span>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Modal",
  props: ["image"],
  emits: ["closeModal"],
  methods: {
    emitCloseModal() {
      this.$emit("closeModal");
    },
  },
};
</script>
