import authActions from "./authActions";
import usersActions from "./usersActions";
import objectsActions from "./objectsActions";
import eventsActions from "./eventsActions";
import postsActions from "./postsActions";
import imageActions from "./imageActions";

export default {
  ...authActions,
  ...usersActions,
  ...objectsActions,
  ...eventsActions,
  ...postsActions,
  ...imageActions,
};
