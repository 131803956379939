<template>
  <div class="container table-responsive">
    <modal v-if="showBlockModal" @closeModal="handleCancel()">
      <template #modalContent>
        <h2 class="modal-question">
          {{
            statusToSet === "BLOCKED"
              ? "Czy chcesz zablokować użytkownika?"
              : "Czy chcesz odblokować użytkownika?"
          }}
        </h2>
        <div class="d-flex">
          <b-button
            style="margin-left: 10px"
            class="btn btn-sm ml-1"
            variant="outline-danger"
            @click="handleBlockUser()"
            >{{ statusToSet === "BLOCKED" ? "Zablokuj" : "Odblokuj" }}
          </b-button>
          <b-button
            style="margin-left: 10px"
            class="btn btn-sm ml-1"
            variant="outline-primary"
            @click="handleCancel($event)"
            >Anuluj
          </b-button>
        </div>
      </template>
    </modal>
    <modal v-if="openDeleteModal" @closeModal="handleCancel()">
      <template #modalContent>
        <h2 class="modal-question">
          {{ `Czy chcesz usunąć użytkownika ${userToDelete}` }}
        </h2>
        <div class="d-flex">
          <b-button
            style="margin-left: 10px"
            class="btn btn-sm ml-1"
            variant="outline-danger"
            @click="handleDelete()"
            >Usuń
          </b-button>
          <b-button
            style="margin-left: 10px"
            class="btn btn-sm ml-1"
            variant="outline-primary"
            @click="handleCancel($event)"
            >Anuluj
          </b-button>
        </div>
      </template>
    </modal>
    <modal v-if="showUserDetails" @closeModal="handleCancel()">
      <template #modalContent>
        <h2 class="modal-question">
          {{ `Użytkownik ${userDetails.name || "-"}` }}
        </h2>
        <p>
          <span class="bolded">Miasto: </span>
          <span>{{ userDetails.city || "-" }}</span>
        </p>
        <p>
          <span class="bolded">Rodzaj użytkownika: </span>
          <span>{{ userTypes[userDetails.userType] }}</span>
        </p>
        <p>
          <span class="bolded">Opis: </span>
          <span>{{ userDetails.description || "-" }}</span>
        </p>
        <p>
          <span class="bolded">Numer telefonu: </span>
          <span>{{ userDetails.phoneNumber || "-" }}</span>
        </p>
        <div class="modal-list-wrapper">
          <span class="bolded">Zainteresowania: </span>
          <section v-if="userDetails.interests.length > 0" class="interests">
            <span
              v-for="{ name, isSpecialisation, id } in userDetails.interests"
              :key="id"
            >
              {{
                `${interestsTransaltions.interests[name]}${
                  isSpecialisation ? " (Specjalizacja)" : ""
                }`
              }}
            </span>
          </section>
          <span v-else>-</span>
        </div>
        <div class="modal-list-wrapper">
          <span class="bolded">Obiekty: </span>
          <section v-if="userDetails.objects.length > 0" class="interests">
            <div
              v-for="object in userDetails.objects"
              :key="object.id"
              class="object"
            >
              <p>
                <span class="bolded">Nazwa: </span>
                <span>{{ object.name || "-" }}</span>
              </p>
              <p>
                <span class="bolded">Opis: </span>
                <span>{{ object.description || "-" }}</span>
              </p>
              <p>
                <span class="bolded">Adres: </span>
                <span>{{ object.address || "-" }}</span>
              </p>
              <p>
                <span class="bolded">Strona internetowa: </span>
                <span>{{ object.website + object.website || "-" }}</span>
              </p>
            </div>
          </section>
          <span v-else>-</span>
        </div>
      </template>
    </modal>
    <image-modal
      v-if="userAvatar"
      :image="userAvatar"
      @closeModal="handleCancel()"
    />
    <p class="h1 fw-light mb-2 mb-sm-4">Użytkownicy</p>
    <div class="filter-wrapper">
      <h2>Filtry</h2>
      <div class="filter-actions-wrapper">
        <div class="filter-object-wrapper">
          <span>Filtr użytkowników</span>
          <input type="text" v-model="searchUser" />
        </div>
        <div class="filter-object-wrapper">
          <span>Filtr numeru telefonu</span>
          <input type="text" v-model="phoneNumber" />
        </div>
        <div>
          <b-button
            style="width: 80px; margin-left: 10px; font-weight: 700"
            class="btn btn-sm ml-1"
            variant="outline-primary"
            id="filter-button"
            @click="handleFetch"
            >Filtruj
          </b-button>
          <b-button
            style="margin-left: 10px; font-weight: 700"
            class="btn btn-sm ml-1"
            variant="outline-primary"
            @click="handleReset"
            >Wyczyść filtr
          </b-button>
        </div>
      </div>
      <h3 style="margin-top: 20px">Filtr rodzajów użytkownika</h3>
      <b-button
        style="width: 80px; margin-left: 10px; font-weight: 700"
        class="btn btn-sm ml-1"
        variant="outline-primary"
        v-bind:class="{ active: statusValue === '' }"
        @click="handleReset"
        >Wszyscy
      </b-button>
      <b-button
        style="margin-left: 10px; font-weight: 700"
        class="btn btn-sm ml-1"
        variant="outline-primary"
        v-bind:class="{ active: statusValue === 'USER' }"
        @click="handleFetchWithStatus('USER')"
        >Użytkownik
      </b-button>
      <b-button
        style="margin-left: 10px; font-weight: 700"
        class="btn btn-sm ml-1"
        variant="outline-primary"
        v-bind:class="{ active: statusValue === 'SPECIALIST' }"
        @click="handleFetchWithStatus('SPECIALIST')"
        >Specjalista
      </b-button>
      <b-button
        style="margin-left: 10px; font-weight: 700"
        class="btn btn-sm ml-1"
        variant="outline-primary"
        v-bind:class="{ active: statusValue === 'SPORTS_CLUB' }"
        @click="handleFetchWithStatus('SPORTS_CLUB')"
        >Klub sportowy
      </b-button>
    </div>
    <table
      class="table align-middle"
      v-if="users && users.length > 0"
      style="margin-bottom: 30px"
    >
      <thead>
        <tr>
          <th v-for="column in columns" :key="column">
            {{ column }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="user in users" :key="user.id">
          <td style="position: relative">
            <div v-if="user.role !== 'ADMIN'" class="action-column">
              <div
                @click="
                  handleShowModal(
                    user.id,
                    `${
                      user.name || user.lastName
                        ? `${user.name || ''} ${user.lastName || ''}`
                        : '-'
                    }`
                  )
                "
                class="danger-button"
              >
                <span>x</span>
                <span class="hint">Usuń użytkownika</span>
              </div>
              <div
                v-if="user.status === 'BLOCKED'"
                @click="
                  handleShowBlockModal({ id: user.id, status: 'ACTIVATED' })
                "
              >
                <img src="../assets/accept.png" alt="Block user" />
                <span class="hint">Odblokuj użytkownika</span>
              </div>
              <div
                v-else
                @click="
                  handleShowBlockModal({ id: user.id, status: 'BLOCKED' })
                "
              >
                <img src="../assets/minus-circle.svg" alt="Block user" />
                <span class="hint">Zablokuj użytkownika</span>
              </div>
            </div>
          </td>
          <td>{{ user.id }}</td>
          <td>
            {{
              user.name || user.lastName
                ? `${user.name || ""} ${user.lastName || ""}`
                : "-"
            }}
          </td>
          <td>{{ user.phoneNumber || "-" }}</td>
          <td>{{ user.email || "-" }}</td>
          <td>{{ user.status ? userStatuses[user.status] : "-" }}</td>
          <td>
            <b-button
              v-if="user.avatar"
              class="btn btn-sm ml-1"
              variant="outline-primary"
              style="margin: 5px 5px 0 0"
              @click="handleShowAvatar(user.avatar.id)"
              >Zdjęcie profilowe</b-button
            >
            <p class="link" @click="handleFetchUserDetails(user.id)">
              Szczegóły
            </p>
            <p class="link" @click="handleRedirect(user)">Zobacz posty</p>
          </td>
        </tr>
      </tbody>
    </table>
    <empty-list-alert v-else :content="'Brak użytkowników'"></empty-list-alert>
    <pagination
      v-if="isDownPaginationVisible"
      class="mx-auto"
      :dispatchActionName="`fetchUsers`"
      :perPage="meta.per_page"
      :pageNumber="meta.current_page"
      :total="meta.total"
      :status="statusValue"
      :search-user="searchUser"
    ></pagination>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import shared from "../shared";
import Pagination from "../components/Pagination";
import EmptyListAlert from "../components/EmptyListAlert.vue";
import ImageModal from "../components/ImageModal.vue";
import Modal from "../components/Modal.vue";
import { USER_STATUSES } from "../utils/statuses";
import { USER_TYPES } from "../utils/types";
import { handleFilterKeyPress } from "../utils/helpers";
import transaltions from "../utils/translations.json";

export default {
  name: "Users",

  components: {
    Pagination,
    EmptyListAlert,
    ImageModal,
    Modal,
  },

  data: () => {
    return {
      columns: [
        "",
        "ID",
        "Imię i nazwisko",
        "Numer telefonu",
        "E-mail",
        "Status",
        "Akcje",
      ],
      statusValue: "",
      openDeleteModal: false,
      userToDelete: "",
      userToDeleteId: "",
      userToBlockOrUnblockId: "",
      userStatuses: { ...USER_STATUSES },
      userTypes: { ...USER_TYPES },
      resetMeta: false,
      userAvatar: "",
      searchUser: "",
      phoneNumber: "",
      statusToSet: "",
      showBlockModal: false,
      showUserDetails: false,
      interestsTransaltions: { ...transaltions },
      userDetails: {},
    };
  },

  methods: {
    ...mapActions([
      "fetchUsers",
      "blockOrUnblockUser",
      "fetchUserPicture",
      "fetchUserDetails",
    ]),
    async handleFetch() {
      try {
        await this.fetchUsers({
          ...this.meta,
          ...(this.resetMeta ? { current_page: 1 } : {}),
          searchUser: this.searchUser,
          phoneNumber: this.phoneNumber
            ? this.phoneNumber.replace("+48", "")
            : "",
          status: this.statusValue,
        });
      } catch (err) {
        if (err.status === 403) {
          this.$toast.open({
            message: `Operacja zabroniona`,
            type: "error",
          });
        } else if (err.status === 404) {
          this.$toast.open({
            message: `Użytkownik nieznaleziony`,
            type: "error",
          });
        } else if (err.status === 500) {
          this.$toast.open({
            message: `Wystąpił błąd serwera`,
            type: "error",
          });
        }
      }
      this.formattedDateAsNumbers = shared.formattedDateAsNumbers;
    },
    async handleBlockUser() {
      try {
        await this.blockOrUnblockUser({
          userId: this.userToBlockOrUnblockId,
          actionType: this.statusToSet,
        });
        this.userToBlockOrUnblockId = "";
        await this.handleFetch();
        this.$toast.open({
          message:
            this.statusToSet === "BLOCKED"
              ? "Zablokowano użytkownika"
              : "Odblokowano użytkownika",
          type: "success",
        });
        this.showBlockModal = false;
      } catch (err) {
        if (err.status === 404) {
          this.$toast.open({
            message: `Użytkownik nieznaleziony`,
            type: "error",
          });
        } else if (err.status === 500) {
          this.$toast.open({
            message: `Wystąpił błąd serwera`,
            type: "error",
          });
        }
      }
    },
    async handleShowAvatar(avatarId) {
      try {
        const response = await this.fetchUserPicture(avatarId);
        if (response) {
          this.userAvatar = response;
        }
      } catch (err) {
        if (err.status === 404) {
          this.$toast.open({
            message: `Nie znaleziono zdjęcia`,
            type: "error",
          });
        } else if (err.status === 500) {
          this.$toast.open({
            message: `Wystąpił błąd serwera`,
            type: "error",
          });
        }
      }
    },
    async handleFetchUserDetails(userId) {
      try {
        this.userDetails = await this.fetchUserDetails({ userId });
        this.showUserDetails = true;
      } catch (err) {
        if (err.status === 404) {
          this.$toast.open({
            message: `Nie znaleziono użytkownika`,
            type: "error",
          });
        } else if (err.status === 500) {
          this.$toast.open({
            message: `Wystąpił błąd serwera`,
            type: "error",
          });
        }
      }
    },
    handleReset() {
      this.searchUser = "";
      this.statusValue = "";
      this.phoneNumber = "";
      this.resetMeta = true;
      this.handleFetch();
      this.resetMeta = false;
    },
    handleCancel() {
      this.userToBlockOrUnblockId = "";
      this.userAvatar = "";
      this.showBlockModal = false;
      this.openDeleteModal = false;
      this.showUserDetails = false;
    },
    handleShowModal(id, name) {
      this.userToDelete = name;
      this.userToDeleteId = id;
      this.openDeleteModal = true;
    },
    handleShowBlockModal(data) {
      this.userToBlockOrUnblockId = data.id;
      this.statusToSet = data.status;
      this.showBlockModal = true;
    },
    handleRedirect(user) {
      this.$router.push(`/posty/${user.name || ""} ${user.lastName || ""}`);
    },
    async handleDelete() {
      try {
        await this.blockOrUnblockUser({
          userId: this.userToDeleteId,
          actionType: "DELETED",
        });
        this.userToDelete = "";
        this.userToDeleteId = "";
        await this.handleFetch();
        this.$toast.open({
          message: `Usunięto użytkownika`,
          type: "success",
        });
      } catch (err) {
        if (err.status === 404) {
          this.$toast.open({
            message: `Nie znaleziono użytkownika`,
            type: "error",
          });
        } else if (err.status === 500) {
          this.$toast.open({
            message: `Wystąpił błąd serwera`,
            type: "error",
          });
        }
      }
      this.formattedDateAsNumbers = shared.formattedDateAsNumbers;
      this.openDeleteModal = false;
    },
    handleFetchWithStatus(status) {
      this.statusValue = status;
      this.resetMeta = true;
      this.handleFetch();
      this.resetMeta = false;
    },
  },

  async created() {
    try {
      await this.fetchUsers({
        per_page: 10,
        current_page: 1,
        searchUser: this.$route.params.userId || "",
        status: "",
      });
      if (this.$route.params.userId && this.users.length > 0) {
        this.searchUser = this.users[0].fullName || "";
      }
      this.formattedDateAsNumbers = shared.formattedDateAsNumbers;
    } catch (err) {
      if (err.status === 500) {
        this.$toast.open({
          message: `Wystąpił błąd serwera`,
          type: "error",
        });
      }
    }
  },

  mounted() {
    window.addEventListener("keypress", handleFilterKeyPress);
  },

  beforeDestroy() {
    window.removeEventListener("keypress", handleFilterKeyPress);
  },

  computed: {
    ...mapGetters({
      users: "getAllUsers",
      meta: "getMeta",
    }),

    // isUpperPaginationVisible() {
    //   return this.users.users && this.users.pagination.total > 20;
    // },

    isDownPaginationVisible() {
      return this.meta && this.meta.total > 20;
    },
  },
};
</script>

<style scoped lang="scss">
tr {
  border-bottom-width: 1px;
}

td {
  border-bottom-width: 0px;
}

.action-column {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-bottom-width: 0px;
}

.action-column div {
  cursor: pointer;
  position: relative;
}

.action-column div img {
  width: 22px;
  height: 22px;
}

.action-column div:hover .hint {
  display: block;
}

.avatar-img {
  display: block;
  width: 50vw;
  height: 70vh;
  object-fit: contain;
  @media screen and (max-width: 768px) {
    width: 75vw;
  }
}

section p {
  margin: 5px 0 0;
  text-align: left;
}

section .interests {
  display: flex;
  flex-direction: column;
}

.object {
  background-color: rgb(232, 232, 232);
  padding: 5px;
  margin-bottom: 5px;
  overflow-wrap: break-word;
}
</style>
