const state = {
  objects: [],
  meta: {
    current_page: 1,
    last_page: 0,
    per_page: 10,
    total: 0,
  },
};

const mutations = {
  SET_OBJECTS(state, objects) {
    state.objects = objects;
  },
  SET_OBJECTS_META(state, meta) {
    state.meta = meta;
  },
};

const getters = {
  getObjects: (state) => state.objects,
  getObjectsMeta: (state) => state.meta,
};

export default {
  state,
  mutations,
  getters,
};
