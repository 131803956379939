<template>
  <div class="container table-responsive">
    <modal v-if="openDeleteModal" @closeModal="handleCancel()">
      <template #modalContent>
        <h2 class="modal-question">
          {{ `Czy chcesz usunąć wydarzenie ${eventToDelete}` }}
        </h2>
        <div class="d-flex">
          <b-button
            style="margin-left: 10px"
            class="btn btn-sm ml-1"
            variant="outline-danger"
            @click="handleDelete()"
            >Usuń
          </b-button>
          <b-button
            style="margin-left: 10px"
            class="btn btn-sm ml-1"
            variant="outline-primary"
            @click="handleCancel($event)"
            >Anuluj
          </b-button>
        </div>
      </template>
    </modal>
    <modal v-if="showAddOrEditModal" @closeModal="handleCancel()">
      <template #modalContent>
        <h2>
          {{
            objectTypeAction === "ADD"
              ? "Dodaj nowe wydarzenie"
              : "Edytuj wydarzenie"
          }}
        </h2>
        <div class="add-object-wrapper">
          <div>
            <label for="name">Opis wydarzenia</label>
            <input
              id="name"
              type="text"
              v-model="eventToAddOrEdit.description"
            />
          </div>
          <div>
            <label for="description">Rozpoczęcie wydarzenia</label>
            <input
              id="description"
              type="datetime-local"
              :min="`${formatter.format(Date.now())}T00:00`"
              :max="
                eventToAddOrEdit.eventEndDate
                  ? `${formatter.format(
                      new Date(eventToAddOrEdit.eventEndDate)
                    )}T00:00`
                  : ''
              "
              v-model="eventToAddOrEdit.eventDate"
            />
          </div>
          <div>
            <label for="eventEndDate">Zakończenie wydarzenia</label>
            <input
              id="eventEndDate"
              type="datetime-local"
              :min="
                eventToAddOrEdit.eventDate
                  ? `${formatter.format(
                      new Date(eventToAddOrEdit.eventDate)
                    )}T00:00`
                  : `${formatter.format(Date.now())}T00:00`
              "
              v-model="eventToAddOrEdit.eventEndDate"
            />
          </div>
          <div>
            <label for="map">Adres</label>
            <vue-google-autocomplete
              id="map"
              v-model="currentAddress"
              placeholder="Zacznij pisać"
              v-on:placechanged="getAddressData"
            />
          </div>
          <div>
            <label for="intersts">Kategorie wydarzenia</label>
            <select
              v-model="eventToAddOrEdit.interestsIds"
              id="intersts"
              multiple
            >
              <option
                v-for="interest in interestsToPick"
                :key="interest.id"
                :value="interest.id"
              >
                {{ interest.name }}
              </option>
            </select>
          </div>
          <div>
            <label for="picutre">Zdjęcie</label>
            <input
              id="picture"
              type="file"
              @change="handleChangeImage"
              accept="image/png, image/jpeg"
            />
          </div>
          <ul v-if="imagesToDsiplay.length" class="images-to-display">
            <li v-for="{ id, src } in imagesToDsiplay" :key="id">
              <img :src="src" />
              <div
                class="modal-cancel-btn"
                style="top: -5px; right: -5px"
                @click="handleDeleteImage(id)"
              >
                x
              </div>
            </li>
          </ul>
        </div>
        <div class="d-flex">
          <b-button
            style="margin-left: 10px"
            class="btn btn-sm ml-1"
            variant="outline-primary"
            @click="handleAddOrEdit()"
            >{{
              objectTypeAction === "ADD"
                ? "Dodaj wydarzenie"
                : "Edytuj wydarzenie"
            }}
          </b-button>
          <b-button
            style="margin-left: 10px"
            class="btn btn-sm ml-1"
            variant="outline-danger"
            @click="handleCancel($event)"
            >Anuluj
          </b-button>
        </div>
      </template>
    </modal>
    <p class="h1 fw-light mb-2 mb-sm-4">Wydarzenia</p>
    <div class="filter-wrapper">
      <h2>Filtry</h2>
      <div class="filter-actions-wrapper">
        <!-- <div class="filter-object-wrapper">
          <span>Filtr nazwy wydarzenia</span>
          <input type="text" v-model="query" />
        </div> -->
        <!-- <div class="filter-object-wrapper">
          <span>Filtr specjalizacji</span>
          <input type="text" v-model="specjalizationFilter" />
        </div> -->
        <div class="filter-object-wrapper">
          <span>Filtr opisu wydarzenia</span>
          <input type="text" v-model="descriptionFilter" />
        </div>
        <div class="filter-object-wrapper">
          <span>Filtr użytkowników</span>
          <input type="text" v-model="userFilter" />
        </div>
        <div>
          <b-button
            style="width: 80px; margin-left: 10px; font-weight: 700"
            class="btn btn-sm ml-1"
            variant="outline-primary"
            id="filter-button"
            @click="handleFetch"
            >Filtruj
          </b-button>
          <b-button
            style="margin-left: 10px; font-weight: 700"
            class="btn btn-sm ml-1"
            variant="outline-primary"
            @click="handleReset"
            >Wyczyść filtr
          </b-button>
        </div>
      </div>
    </div>
    <b-button
      style="margin-bottom: 10px; font-weight: 700"
      class="btn btn-sm ml-1"
      variant="outline-primary"
      @click="handleShowAddOrEditModal('ADD', {})"
    >
      Dodaj nowe wydarzenie
    </b-button>
    <table
      class="table align-middle"
      v-if="events && events.length > 0"
      style="margin-bottom: 30px"
    >
      <thead>
        <tr>
          <th v-for="column in columns" :key="column">
            {{ column }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="event in events" :key="event.id">
          <td style="position: relative">
            <div class="action-column">
              <div
                @click="handleShowModal(event.id, event.description)"
                class="danger-button"
              >
                <span>x</span>
                <span class="hint">Usuń wydarzenie</span>
              </div>
            </div>
          </td>
          <td>
            {{ event.description || "-" }}
          </td>
          <td>
            {{
              event.eventDate
                ? `${event.eventDate.slice(0, 10)} ${event.eventDate.slice(
                    11,
                    19
                  )}`
                : "-"
            }}
          </td>
          <td>
            {{
              event.eventEndDate
                ? `${event.eventEndDate.slice(
                    0,
                    10
                  )} ${event.eventEndDate.slice(11, 19)}`
                : "-"
            }}
          </td>
          <td>
            {{ event.eventAuthor ? event.eventAuthor.name : "-" }}
          </td>
          <td>
            <p class="link" @click="handleShowAddOrEditModal('EDIT', event)">
              Edytuj wydarzenie
            </p>
            <p class="link" @click="handleShowGallery(event.images)">
              Pokaż Zdjęcia
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <empty-list-alert v-else :content="'Brak wydarzeń'"></empty-list-alert>
    <gallery
      v-if="showImagesModal"
      :images="imagesToDsiplay"
      @close="handleCloseGallery"
    />
    <pagination
      v-if="isDownPaginationVisible"
      class="mx-auto"
      :dispatchActionName="`fetchEvents`"
      :perPage="meta.per_page"
      :pageNumber="meta.current_page"
      :total="meta.total"
      :status="statusValue"
      :search-user="query"
    ></pagination>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import shared from "../shared";
import Pagination from "../components/Pagination";
import Gallery from "../components/Gallery.vue";
import EmptyListAlert from "../components/EmptyListAlert.vue";
import Modal from "../components/Modal.vue";
import { handleFilterKeyPress } from "../utils/helpers";
import VueGoogleAutocomplete from "vue-google-autocomplete";

export default {
  name: "Events",

  components: {
    Pagination,
    EmptyListAlert,
    Modal,
    Gallery,
    VueGoogleAutocomplete,
  },

  data: () => {
    return {
      columns: [
        "",
        "Opis",
        "Data rozpoczęcia",
        "Data zakończenia wydarzenia",
        "Autor",
        "Akcje",
      ],
      statusValue: "",
      openDeleteModal: false,
      eventToDelete: "",
      eventToDeleteId: "",
      resetMeta: false,
      query: "",
      formatter: new Intl.DateTimeFormat("fr-CA", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }),
      specjalizationFilter: "",
      descriptionFilter: "",
      userFilter: "",
      statusToSet: "",
      currentAddress: "",
      showBlockModal: false,
      detailsObject: {},
      minDate: `${new Date().getFullYear()}-${
        new Date().getMonth() + 1 < 10
          ? `0${new Date().getMonth() + 1}`
          : new Date().getMonth() + 1
      }-${
        new Date().getDate() < 10
          ? `0${new Date().getDate()}`
          : new Date().getDate()
      }`,
      WEEK_DAYS: [
        "Poniedziałek",
        "Wtorek",
        "Środa",
        "Czwartek",
        "Piątek",
        "Sobota",
        "Niedziela",
      ],
      eventToAddOrEdit: {
        description: "",
        eventDate: "",
        eventEndDate: "",
        latitude: "",
        longitude: "",
        interestsIds: [],
      },
      showAddOrEditModal: false,
      showImagesModal: false,
      objectTypeAction: "ADD",
      objectImages: [],
      interestsToPick: [],
      FILE_TYPES: ["jpg", "jpeg", "png"],
    };
  },

  methods: {
    ...mapActions([
      "fetchEvents",
      "addEvent",
      "editEvent",
      "deleteEvent",
      "addImage",
      "fetchPicture",
      "deleteImage",
      "fetchInterests",
    ]),
    async handleFetch() {
      try {
        await this.fetchEvents({
          ...this.meta,
          ...(this.resetMeta ? { current_page: 1 } : {}),
          query: this.query,
          description: this.descriptionFilter,
          user: this.userFilter,
          status: this.statusValue,
        });
      } catch (err) {
        if (err.status === 403) {
          this.$toast.open({
            message: `Operacja zabroniona`,
            type: "error",
          });
        } else if (err.status === 404) {
          this.$toast.open({
            message: `Wydarzenie nieznalezione`,
            type: "error",
          });
        } else if (err.status === 500) {
          this.$toast.open({
            message: `Wystąpił błąd serwera`,
            type: "error",
          });
        }
      }
      this.formattedDateAsNumbers = shared.formattedDateAsNumbers;
    },
    async handleReset() {
      this.query = "";
      this.statusValue = "";
      this.descriptionFilter = "";
      this.userFilter = "";
      this.resetMeta = true;
      await this.handleFetch();
      this.resetMeta = false;
    },
    async handleCancel() {
      this.eventToDelete = "";
      this.eventToDeleteId = "";
      this.eventToAddOrEdit = {
        description: "",
        eventDate: "",
        latitude: "",
        longitude: "",
        interestsIds: [],
      };
      this.showBlockModal = false;
      this.openDeleteModal = false;
      this.showAddOrEditModal = false;
      this.currentAddress = "";
      this.objectImages = [];
      await this.handleFetch();
    },
    handleShowModal(id, name) {
      this.eventToDelete = name;
      this.eventToDeleteId = id;
      this.openDeleteModal = true;
    },
    getAddressData(addressData) {
      const { latitude, longitude } = addressData;
      this.eventToAddOrEdit.longitude = longitude;
      this.eventToAddOrEdit.latitude = latitude;
    },
    async handleShowGallery(images) {
      if (images && images.length) {
        this.objectImages = await Promise.all(
          images.map((img) => this.fetchPicture(img.id))
        );
        this.showImagesModal = true;
      } else {
        this.$toast.open({
          message: `Galeria jest pusta`,
          type: "error",
        });
      }
    },
    handleCloseGallery() {
      this.objectImages = [];
      this.showImagesModal = false;
    },

    async handleDeleteImage(id) {
      const imageToDeleteIndex = this.objectImages.findIndex(
        (img) => img.id === id
      );
      if (imageToDeleteIndex !== -1) {
        this.objectImages.splice(imageToDeleteIndex, 1);
        if (typeof id === "number") {
          await this.deleteImage(id);
        }
      } else {
        this.$toast.open({
          message: `Nie znaleziono zdjęcia`,
          type: "error",
        });
      }
    },
    handleChangeImage(e) {
      const file = e.target.files[0];
      const extension = file.name.split(".").pop();
      if (this.FILE_TYPES.includes(extension)) {
        if (this.objectImages.length < 5) {
          this.objectImages.push({
            id: `${new Date().getTime()}`,
            file,
          });
        } else {
          this.$toast.open({
            message: `Maksymalna licza zdjęć to 5`,
            type: "error",
          });
        }
      } else {
        this.$toast.open({
          message: `Możliwe rodzaje plików to: jpg, jpeg i png`,
          type: "error",
        });
      }
    },
    async handleShowAddOrEditModal(type, data) {
      this.objectTypeAction = type;
      if (type === "EDIT") {
        const tempId = data.id;
        this.eventToAddOrEdit.description = data.description;
        this.eventToAddOrEdit.eventDate = data.eventDate.slice(0, 16);
        this.eventToAddOrEdit.eventEndDate = data.eventEndDate.slice(0, 16);
        this.eventToAddOrEdit.objectId = tempId;
        this.eventToAddOrEdit.id = tempId;
        this.eventToAddOrEdit.latitude = data.latitude;
        this.eventToAddOrEdit.longitude = data.longitude;
        this.eventToAddOrEdit.interestsIds = data.interests?.map(
          (interest) => interest.id
        );
        if (data.latitude && data.longitude) {
          const geocoder = new window.google.maps.Geocoder();
          const latLng = new window.google.maps.LatLng(
            data.latitude,
            data.longitude
          );
          geocoder.geocode({ location: latLng }, (results, status) => {
            if (
              status === window.google.maps.GeocoderStatus.OK &&
              results.length > 0
            ) {
              const address = results[0].formatted_address;
              this.currentAddress = address;
            }
          });
        }
        if (data.images && data.images.length)
          this.objectImages = await Promise.all(
            data.images.map((img) => this.fetchPicture(img.id))
          );
      }
      this.showAddOrEditModal = true;
    },
    async handleDelete() {
      try {
        await this.deleteEvent({
          id: this.eventToDeleteId,
        });
        this.eventToDelete = "";
        this.eventToDeleteId = "";
        await this.handleFetch();
        this.$toast.open({
          message: `Usunięto wydarzenie`,
          type: "success",
        });
      } catch (err) {
        if (err.status === 404) {
          this.$toast.open({
            message: `Nie znaleziono wydarzenia`,
            type: "error",
          });
        } else if (err.status === 500) {
          this.$toast.open({
            message: `Wystąpił błąd serwera`,
            type: "error",
          });
        }
      }
      this.formattedDateAsNumbers = shared.formattedDateAsNumbers;
      this.openDeleteModal = false;
    },
    async handleAddOrEdit() {
      try {
        if (
          this.eventToAddOrEdit.description &&
          this.eventToAddOrEdit.eventDate &&
          this.eventToAddOrEdit.eventEndDate &&
          this.eventToAddOrEdit.interestsIds.length &&
          this.eventToAddOrEdit.latitude &&
          this.eventToAddOrEdit.longitude
        ) {
          let response = null;
          if (this.objectTypeAction === "ADD") {
            response = await this.addEvent(this.eventToAddOrEdit);
          } else if (this.objectTypeAction === "EDIT") {
            response = await this.editEvent(this.eventToAddOrEdit);
          } else {
            this.$toast.open({
              message: `Wsytąpił błąd z typem akcji na wydarzeniu`,
              type: "error",
            });
          }
          this.eventToAddOrEdit = {
            description: "",
            eventDate: "",
            interestsIds: [],
            latitude: "",
            longitude: "",
          };
          this.currentAddress = "";
          if (this.objectImages.length && response) {
            console.log("test");
            await Promise.all(
              this.objectImages
                .map((img) =>
                  typeof img.id === "string"
                    ? this.addImage({
                        file: img.file,
                        eventId: response.id,
                        type: "EVENT",
                      })
                    : undefined
                )
                .filter((e) => e !== undefined)
            );
          }
          this.objectImages = [];
          this.$toast.open({
            message: `Wydarzenie dodane pomyślnie`,
            type: "success",
          });
          await this.handleFetch();
          this.showAddOrEditModal = false;
        } else if (
          !this.eventToAddOrEdit.latitude ||
          !this.eventToAddOrEdit.longitude
        ) {
          this.$toast.open({
            message: `Nie znaleziono adresu`,
            type: "error",
          });
        } else {
          this.$toast.open({
            message: `Wszystkie pola są wymagane`,
            type: "error",
          });
        }
      } catch (err) {
        if (err.status === 404) {
          this.$toast.open({
            message: `Nie znaleziono wydarzenia`,
            type: "error",
          });
        } else if (err.status === 500) {
          this.$toast.open({
            message: `Wystąpił błąd serwera`,
            type: "error",
          });
        }
      }
    },
    handleFetchWithStatus(status) {
      this.statusValue = status;
      this.resetMeta = true;
      this.handleFetch();
      this.resetMeta = false;
    },
  },

  async created() {
    try {
      await this.fetchEvents({
        per_page: 10,
        current_page: 1,
        query: "",
        status: "",
      });
      this.interestsToPick = await this.fetchInterests();
      this.formattedDateAsNumbers = shared.formattedDateAsNumbers;
    } catch (err) {
      if (err.status === 500) {
        this.$toast.open({
          message: `Wystąpił błąd serwera`,
          type: "error",
        });
      }
    }
  },

  mounted() {
    window.addEventListener("keypress", handleFilterKeyPress);
  },

  beforeDestroy() {
    window.removeEventListener("keypress", handleFilterKeyPress);
  },

  computed: {
    ...mapGetters({
      events: "getEvents",
      meta: "getEventsMeta",
    }),
    imagesToDsiplay() {
      return this.objectImages.map((img) => ({
        id: img.id,
        src: URL.createObjectURL(img.file),
      }));
    },

    isDownPaginationVisible() {
      return this.meta && this.meta.total > 20;
    },
  },
};
</script>

<style scoped lang="scss">
tr {
  border-bottom-width: 1px;
}

td {
  border-bottom-width: 0px;
  max-height: 120px;
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.action-column {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-bottom-width: 0px;
}

.action-column div {
  cursor: pointer;
  position: relative;
}

.action-column div img {
  width: 22px;
  height: 22px;
}

.action-column div:hover .hint {
  display: block;
}
</style>
