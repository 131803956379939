<template>
  <div class="container table-responsive">
    <modal v-if="openDeleteModal" @closeModal="handleCancel()">
      <template #modalContent>
        <h2 class="modal-question">
          {{ `Czy chcesz usunąć post?` }}
        </h2>
        <div class="d-flex">
          <b-button
            style="margin-left: 10px"
            class="btn btn-sm ml-1"
            variant="outline-danger"
            @click="handleDelete()"
            >Usuń
          </b-button>
          <b-button
            style="margin-left: 10px"
            class="btn btn-sm ml-1"
            variant="outline-primary"
            @click="handleCancel($event)"
            >Anuluj
          </b-button>
        </div>
      </template>
    </modal>
    <modal
      v-if="showDeleteCommentModal"
      @closeModal="() => (showDeleteCommentModal = false)"
      style="z-index: 999999"
    >
      <template #modalContent>
        <h2 class="modal-question" style="margin-bottom: 40px">
          Usunąć komentarz?
        </h2>
        <div class="d-flex">
          <b-button
            style="margin-left: 10px; padding: 10px 20px; width: 110px"
            class="btn btn-sm ml-1"
            variant="outline-danger"
            @click="handleDeleteComment()"
            >Potwierdź
          </b-button>
          <b-button
            style="margin-left: 30px; padding: 10px 20px; width: 110px"
            class="btn btn-sm ml-1"
            variant="outline-primary"
            @click="() => (showDeleteCommentModal = false)"
            >Anuluj
          </b-button>
        </div>
      </template>
    </modal>
    <modal v-if="showCommentsModal" @closeModal="handleCancel()">
      <template #modalContent>
        <h2 class="modal-question">
          {{ comments.length > 0 ? "Komentarze do postu" : "Brak komentarzy" }}
        </h2>
        <div v-if="comments.length > 0" class="comments-wrapper">
          <p>
            <span style="font-weight: 700">Użytkownik</span>
            <span style="font-weight: 700">Komentarz</span>
            <span style="font-weight: 700">Akcje</span>
          </p>
          <p v-for="comment in comments" :key="comment.id" class="comment-item">
            <span>{{ comment.author ? comment.author.name : "-" }}</span>
            <span>{{ comment.text || "-" }}</span>
            <span
              class="delete-comment-btn"
              style="width: 20px"
              @click="handleShowDeleteCommentModal(comment.id)"
              >x</span
            >
          </p>
        </div>
      </template>
    </modal>
    <p class="h1 fw-light mb-2 mb-sm-4">Posty</p>
    <div class="filter-wrapper">
      <h2>Filtry</h2>
      <div class="filter-actions-wrapper">
        <div class="filter-object-wrapper">
          <span>Filtr użytkowników</span>
          <input type="text" v-model="query" />
        </div>
        <b-button
          style="width: 80px; margin-left: 10px; font-weight: 700"
          class="btn btn-sm ml-1"
          variant="outline-primary"
          id="filter-button"
          @click="handleFetch"
          >Filtruj
        </b-button>
        <b-button
          style="margin-left: 10px; font-weight: 700"
          class="btn btn-sm ml-1"
          variant="outline-primary"
          @click="handleReset"
          >Wyczyść filtr
        </b-button>
      </div>
    </div>
    <table
      class="table align-middle"
      v-if="posts && posts.length > 0"
      style="margin-bottom: 30px"
    >
      <thead>
        <tr>
          <th v-for="column in columns" :key="column">
            {{ column }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="post in posts" :key="post.id">
          <td style="position: relative" @click="handleShowModal(post.postId)">
            <div class="action-column">
              <div class="danger-button">
                <span>x</span>
                <span class="hint">Usuń post</span>
              </div>
            </div>
          </td>
          <td>{{ post.description || "-" }}</td>
          <td>{{ post.author ? post.author.name : "-" }}</td>
          <td>
            {{ post.createdAt ? post.createdAt.slice(0, 10) : "-" }}
          </td>
          <td>{{ post.modifiedAt ? post.modifiedAt.slice(0, 10) : "-" }}</td>
          <td>{{ post.likeCount || "0" }}</td>
          <td>
            <span class="link" @click="handleShowComments(post.postId)"
              >Zobacz komentarze</span
            >
          </td>
        </tr>
      </tbody>
    </table>
    <empty-list-alert v-else :content="'Brak postów'"></empty-list-alert>
    <pagination
      v-if="isDownPaginationVisible"
      class="mx-auto"
      :dispatchActionName="`fetchPosts`"
      :perPage="meta.per_page"
      :pageNumber="meta.current_page"
      :total="meta.total"
      :status="statusValue"
      :search-user="query"
    ></pagination>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import shared from "../shared";
import Pagination from "../components/Pagination";
import EmptyListAlert from "../components/EmptyListAlert.vue";
import Modal from "../components/Modal.vue";
import { handleFilterKeyPress } from "../utils/helpers";

export default {
  name: "Posts",

  components: {
    Pagination,
    EmptyListAlert,
    Modal,
  },

  data: () => {
    return {
      columns: [
        "",
        "Opis",
        "Autor",
        "Utworzony",
        "Ostatnia modyfiakcja",
        "Liczba polubień",
        "Akcje",
      ],
      statusValue: "",
      openDeleteModal: false,
      postToDeleteId: "",
      resetMeta: false,
      query: "",
      statusToSet: "",
      showBlockModal: false,
      showCommentsModal: false,
      showDeleteCommentModal: false,
      postComments: [],
      commentToDeleteId: "",
    };
  },

  methods: {
    ...mapActions([
      "fetchPosts",
      "deletePost",
      "showComments",
      "deleteComment",
    ]),
    async handleFetch() {
      try {
        await this.fetchPosts({
          ...this.meta,
          ...(this.resetMeta ? { current_page: 1 } : {}),
          query: this.query,
          status: this.statusValue,
        });
      } catch (err) {
        if (err.status === 403) {
          this.$toast.open({
            message: `Operacja zabroniona`,
            type: "error",
          });
        } else if (err.status === 404) {
          this.$toast.open({
            message: `Nieznaleziono postów`,
            type: "error",
          });
        } else if (err.status === 500) {
          this.$toast.open({
            message: `Wystąpił błąd serwera`,
            type: "error",
          });
        }
      }
      this.formattedDateAsNumbers = shared.formattedDateAsNumbers;
    },
    handleReset() {
      this.query = "";
      this.statusValue = "";
      this.resetMeta = true;
      this.handleFetch();
      this.resetMeta = false;
    },
    handleCancel() {
      this.showBlockModal = false;
      this.openDeleteModal = false;
      this.showCommentsModal = false;
    },
    handleShowModal(id) {
      this.postToDeleteId = id;
      this.openDeleteModal = true;
    },
    async handleShowComments(postId) {
      try {
        const response = await this.showComments({ postId });
        console.log(response);
        this.comments = response.data;
        this.showCommentsModal = true;
      } catch (err) {
        if (err.status === 404) {
          this.$toast.open({
            message: `Nie znaleziono komentarzy do postu`,
            type: "error",
          });
        } else if (err.status === 500) {
          this.$toast.open({
            message: `Wystąpił błąd serwera`,
            type: "error",
          });
        }
      }
    },
    handleShowDeleteCommentModal(commendId) {
      this.showDeleteCommentModal = true;
      this.commentToDeleteId = commendId;
    },
    async handleDelete() {
      try {
        await this.deletePost({
          id: this.postToDeleteId,
        });
        this.postToDeleteId = "";
        await this.handleFetch();
        this.$toast.open({
          message: `Usunięto post`,
          type: "success",
        });
      } catch (err) {
        if (err.status === 404) {
          this.$toast.open({
            message: `Nie znaleziono postu`,
            type: "error",
          });
        } else if (err.status === 500) {
          this.$toast.open({
            message: `Wystąpił błąd serwera`,
            type: "error",
          });
        }
      }
      this.formattedDateAsNumbers = shared.formattedDateAsNumbers;
      this.openDeleteModal = false;
    },
    async handleDeleteComment() {
      try {
        await this.deleteComment({
          id: this.commentToDeleteId,
        });
        this.commentToDeleteId = "";
        await this.handleFetch();
        this.$toast.open({
          message: `Usunięto komentarz`,
          type: "success",
        });
      } catch (err) {
        if (err.status === 404) {
          this.$toast.open({
            message: `Nie znaleziono komentarza`,
            type: "error",
          });
        } else if (err.status === 500) {
          this.$toast.open({
            message: `Wystąpił błąd serwera`,
            type: "error",
          });
        }
      }
      this.showDeleteCommentModal = false;
    },
    handleFetchWithStatus(status) {
      this.statusValue = status;
      this.resetMeta = true;
      this.handleFetch();
      this.resetMeta = false;
    },
  },

  async created() {
    try {
      await this.fetchPosts({
        per_page: 10,
        current_page: 1,
        query: this.$route.params.userName.trim() || "",
        status: "",
      });
      if (this.$route.params.userName) {
        this.query = this.$route.params.userName.trim() || "";
      }
      this.formattedDateAsNumbers = shared.formattedDateAsNumbers;
    } catch (err) {
      if (err.status === 500) {
        this.$toast.open({
          message: `Wystąpił błąd serwera`,
          type: "error",
        });
      }
    }
  },

  mounted() {
    window.addEventListener("keypress", handleFilterKeyPress);
  },

  beforeDestroy() {
    window.removeEventListener("keypress", handleFilterKeyPress);
  },

  computed: {
    ...mapGetters({
      posts: "getPosts",
      meta: "getPostsMeta",
    }),

    // isUpperPaginationVisible() {
    //   return this.users.users && this.users.pagination.total > 20;
    // },

    isDownPaginationVisible() {
      return this.meta && this.meta.total > 20;
    },
  },
};
</script>

<style scoped lang="scss">
tr {
  border-bottom-width: 1px;
}

td {
  border-bottom-width: 0px;
  max-height: 120px;
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.action-column {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-bottom-width: 0px;
}

.action-column div {
  cursor: pointer;
  position: relative;
}

.action-column div img {
  width: 22px;
  height: 22px;
}

.action-column div:hover .hint {
  display: block;
}

.avatar-img {
  display: block;
  width: 50vw;
  height: 70vh;
  object-fit: contain;
  @media screen and (max-width: 768px) {
    width: 75vw;
  }
}

section p {
  margin: 5px 0 0;
  text-align: left;
}

section .interests {
  display: flex;
  flex-direction: column;
}

.object {
  background-color: rgb(232, 232, 232);
  padding: 5px;
  margin-bottom: 5px;
  overflow-wrap: break-word;
}
</style>
