const state = {
  events: [],
  meta: {
    current_page: 0,
    last_page: 0,
    per_page: 0,
    total: 0,
  },
};

const mutations = {
  SET_EVENTS(state, events) {
    state.events = events;
  },
  SET_EVENTS_META(state, meta) {
    state.meta = meta;
  },
};

const getters = {
  getEvents: (state) => state.events,
  getEventsMeta: (state) => state.meta,
};

export default {
  state,
  mutations,
  getters,
};
