<template>
  <div class="alert alert-danger not-found-list-alert mx-auto w-full">
    {{ content }}
  </div>
</template>

<script>
export default {
  name: 'EmptyListAlert',
  props: ['content'],
};
</script>

<style scoped>
.not-found-list-alert {
  width: 80%;
}
</style>
