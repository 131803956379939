import axios from "../../axios";

const fetchObjects = async ({ commit }, data) => {
  const perPage = data.per_page ? `limit=${data.per_page}&` : "";
  const pageNumber = data.current_page ? `page=${data.current_page}&` : "";
  const query = data.query ? `query=${data.query}&` : "";
  const searchUser = data.user ? `user=${data.user}&` : "";
  const description = data.description
    ? `description=${data.description}&`
    : "";
  const status = data.status ? `status=${data.status}` : "";
  const url = `admins/objects?${perPage}${pageNumber}${query}${searchUser}${description}${status}`;
  try {
    const response = await axios.get(url);
    commit("SET_OBJECTS", response.data.data);
    commit("SET_OBJECTS_META", response.data.meta);
  } catch (e) {
    throw { status: e.response.status };
  }
};

const addObject = async (_, data) => {
  const response = await axios.post(`admins/objects`, data);
  return response.data;
};

const editObject = async (_, data) => {
  const objectId = data.objectId;
  const body = Object.assign({}, data);
  delete body.objectId;
  const response = await axios.put(`admins/objects/${objectId}`, body);
  return response.data;
};

const deleteObject = async (_, data) => {
  try {
    await axios.delete(`admins/objects/${data.objectId}`);
  } catch (err) {
    throw { status: err.response.status };
  }
};

export default {
  fetchObjects,
  deleteObject,
  addObject,
  editObject,
};
