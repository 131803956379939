import axios from "../../axios";

const fetchEvents = async ({ commit }, data) => {
  const perPage = data.per_page ? `limit=${data.per_page}&` : "";
  const pageNumber = data.current_page ? `page=${data.current_page}&` : "";
  const query = data.query ? `query=${data.query}&` : "";
  const searchUser = data.user ? `user=${data.user}&` : "";
  const description = data.description
    ? `description=${data.description}&`
    : "";
  const status = data.status ? `status=${data.status}` : "";
  const url = `admins/events?${perPage}${pageNumber}${query}${status}${searchUser}${description}`;
  try {
    const response = await axios.get(url);
    commit("SET_EVENTS", response.data.data);
    commit("SET_EVENTS_META", response.data.meta);
  } catch (e) {
    throw { status: e.response.status };
  }
};

const addEvent = async (_, data) => {
  try {
    const response = await axios.post(`admins/events`, data);
    return response.data;
  } catch (err) {
    throw { status: err.response.status };
  }
};

const editEvent = async (_, data) => {
  const eventId = data.id;
  const body = Object.assign({}, data);
  delete body.objectId;
  try {
    const response = await axios.put(`admins/events/${eventId}`, body);
    return response.data;
  } catch (err) {
    throw { status: err.response.status };
  }
};

const deleteEvent = async (_, data) => {
  try {
    await axios.delete(`admins/events/${data.id}`);
  } catch (err) {
    throw { status: err.response.status };
  }
};

const fetchInterests = async () => {
  try {
    const response = await axios.get("interests");
    return response.data.data;
  } catch (err) {
    throw { status: err.response.status };
  }
};

export default {
  fetchEvents,
  deleteEvent,
  addEvent,
  editEvent,
  fetchInterests,
};
