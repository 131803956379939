import axios from "../../axios.js";
import router from "../../router/index.js";

const login = async ({ commit }, data) => {
  try {
    const response = await axios.post(`auth/login`, data);
    if (response && response.data && response.data.access_token) {
      commit("SET_TOKEN", response.data.access_token);
      localStorage.setItem("PICKME_TOKEN", response.data.access_token);
      router.push("/users");
      return true;
    } else {
      return false;
    }
  } catch (error) {
    throw { status: error.response.status };
  }
};

const verify = async ({ commit }, data) => {
  try {
    const response = await axios.post("auth/verify", data);
    if (response && response.data && response.data.data.accessToken) {
      commit("SET_TOKEN", response.data.data.accessToken);
      localStorage.setItem("PICKME_TOKEN", response.data.data.accessToken);
      router.push("/users");
      return true;
    } else {
      return false;
    }
  } catch (error) {
    throw { status: error.response.status };
  }
};

const logout = ({ commit }) => {
  commit("SET_TOKEN", null), localStorage.removeItem("PICKME_TOKEN");
  router.push("/login");
};

export default {
  login,
  logout,
  verify,
};
