const state = {
  posts: [],
  meta: {
    current_page: 0,
    last_page: 0,
    per_page: 0,
    total: 0,
  },
};

const mutations = {
  SET_POSTS(state, posts) {
    state.posts = posts;
  },
  SET_META_POSTS(state, meta) {
    state.meta = meta;
  },
};

const getters = {
  getPosts: (state) => state.posts,
  getPostsMeta: (state) => state.meta,
};

export default {
  state,
  mutations,
  getters,
};
