import VueRouter from "vue-router";
import Vue from "vue";
import axios from "../axios";
import Login from "../pages/Login";
import Users from "../pages/Users";
import Objects from "../pages/Objects";
import Posts from "../pages/Posts";
import Events from "../pages/Events";
import PaymentProcessing from "../pages/PaymentProcessing";
// import Payments from "../pages/Payments";
// import UsersChats from "../pages/UsersChats";
// import Transactions from "../pages/Transactions";
// import Reports from "../pages/Reports";
const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/users/:userId?",
    name: "Users",
    component: Users,
  },
  {
    path: "/obiekty",
    name: "Objects",
    component: Objects,
  },
  {
    path: "/posty/:userName?",
    name: "Posts",
    component: Posts,
  },
  {
    path: "/wydarzenia",
    name: "Events",
    component: Events,
  },
  {
    path: "/payment-processing",
    name: "PaymentProcessing",
    component: PaymentProcessing,
  },
  // {
  //   path: "/transakcje/:userId?",
  //   name: "Transactions",
  //   component: Transactions,
  // },
  // {
  //   path: "/zgloszenia",
  //   name: "Reports",
  //   component: Reports,
  // },
  // {
  //   path: "/payment-processing",
  //   name: "PaymentProcessing",
  //   component: PaymentProcessing,
  // },
  // {
  //   path: "/chats/:userId",
  //   name: "Chats",
  //   component: () => import("../pages/Chats.vue"),
  //   props: true,
  //   children: [
  //     {
  //       path: ":chatId",
  //       name: "ChatContent",
  //       component: () => import("../pages/ChatContent.vue"),
  //       props: true,
  //     },
  //   ],
  // },

  // {
  //   path: "/notifications",
  //   name: "Notifications",
  //   component: () => import("../pages/Notifications.vue"),
  // },
  // {
  //   path: "/notifications/create",
  //   name: "CreateNotification",
  //   component: () => import("../pages/CreateNotification.vue"),
  // },
  // {
  //   path: "/notifications/createAtStart",
  //   name: "CreateNotificationAtStart",
  //   component: () => import("../pages/CreateNotificationAtStart.vue"),
  // },
  // {
  //   path: "/documents",
  //   name: "Documents",
  //   component: () => import("../pages/Documents.vue"),
  // },
  // {
  //   path: "/documents/create",
  //   name: "AddDocument",
  //   component: () => import("../pages/AddDocuments.vue"),
  // },
  // {
  //   path: "/documents/edit/:documentId",
  //   name: "EditDocument",
  //   component: () => import("../pages/EditDocument.vue"),
  //   props: true,
  // },
];
Vue.use(VueRouter);
const router = new VueRouter({
  mode: "history",
  base: process.env.NODE_ENV === "production" ? "/admin" : "",
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name !== "PaymentProcessing") {
    const token = window.localStorage.getItem("PICKME_TOKEN");
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    axios.defaults.headers.common["Accept-Language"] = "pl";
    axios.defaults.headers.common["Content-Language"] = "pl";
    if (to.name !== "Login" && !token) next({ name: "Login" });
    else next();
    next();
  } else {
    next();
  }
});

export default router;
